<template>
  <div class="world quote-page">
    <div class="wrapper-title">
      <div class="container">
        <button class="text-wilmsLightBlue font-100 block" @click="backToOverview">&larr; {{ $t('Back to overview') }}</button>
        <div class="flex">
          <div>
            <h1 v-if="quote">{{ $t('Offerte') + ' ' + quote.number }}</h1>
            <div class="intro">
              <p>{{ $t('Wij geven je een accurate offerte in slechts enkele stappen!') }}</p>
            </div>
          </div>
          <div v-if="quote" class="buttons">
            <button class="btn btn-config btn-config-articulating-screens" @click="addArticulatingScreen">
              <div class="icon"><img alt="Zonnescherm icoon" src="@/assets/img/icon-articulating-screens.svg"></div>
              <div class="text"><span>{{ $t('Configureer') }}</span>{{ $t('Zonnescherm') }}</div>
            </button>
            <router-link :to="{ name: 'ww.quotes.calc.screen_select', params: { quoteId: quote.id } }" class="btn btn-config btn-config-screens">
              <div class="icon"><img alt="Screens icoon" src="@/assets/img/icon-screens.svg"></div>
              <div class="text"><span>{{ $t('Configureer') }}</span>{{ $t('Screen') }}</div>
            </router-link>
            <router-link :to="{ name: 'ww.quotes.calc.shutter_select', params: { quoteId: quote.id } }" class="btn btn-config btn-config-shutters">
              <div class="icon"><img alt="Shutters icoon" src="@/assets/img/icon-shutters.svg"></div>
              <div class="text"><span>{{ $t('Configureer') }}</span>{{ $t('Rolluik') }}</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="steps-wrapper">
        <div class="step-buttons">
          <CalculatorButton :activeStep="activeStep" :disabled="loading" :label=" $t('Klantgegevens') " :type="'info'" @setStep="setStep"/>
          <CalculatorButton :activeStep="activeStep" :disabled="loading" :label=" $t('Extra\'s') " :type="'extras'" @setStep="setStep"/>
          <CalculatorButton :activeStep="activeStep" :disabled="loading" :label=" $t('Plaatsingskosten') " :type="'installation_costs'" @setStep="setStep"/>
          <CalculatorButton :activeStep="activeStep" :disabled="loading" :label=" $t('Offerte') " :type="'overview'" @setStep="setStep"/>
        </div>

        <div v-if="quote" class="step-contents">
          <div v-if="activeStep === 'extras'" :class="'step-' + activeStep" class="step">
            <h2>{{ $t('Extra\'s') }}</h2>
            <div class="help">
              {{ $t('Configureer extra rubrieken met een welbepaalde prijs die je specifiek voor deze offerte wenst te calculeren. Algemene kosten die voor al jouw offertes gelden zoals bijv. de plaatsingskost, kan je configureren onder de rubriek Dealerinformatie') }}
            </div>

            <div class="extras">
              <v-select v-model="selectExtras" :options="extras" label="title" multiple @option:selected="selectExtras = []" @option:selecting="selectExtra"></v-select>

              <formulate-form ref="extrasForm" class="extras-form" name="extrasForm">
                <formulate-input v-model="quoteExtras" :add-label="$t('+ Extra toevoegen')" :repeatable="true" groupRepeatable-class="extra-option-row translations-group formulate-input-group-repeatable" name="extras" remove-label="" type="group">
                  <formulate-input :label="$t('Titel extra optie')" :outer-class="['col-2']" name="title" type="text" validation="required"></formulate-input>
                  <formulate-input :label="$t('Prijs')" name="price" prefix="€" type="number" validation="number">
                    <template v-slot:prefix>
                      <div class="prefix">€</div>
                    </template>
                  </formulate-input>
                  <formulate-input :label="$t('Percentage')" name="percentage" prefix="€" type="number" validation="number">
                    <template v-slot:prefix>
                      <div class="prefix">%</div>
                    </template>
                  </formulate-input>
                  <formulate-input :label="$t('Omschrijving')" :outer-class="['col-2']" name="description" type="textarea" value=""></formulate-input>
                </formulate-input>
              </formulate-form>
            </div>

            <div class="actions">
              <button class="btn" @click="validateExtras('overview')">{{ $t('Volgende') }}</button>
            </div>
          </div>

          <div v-if="activeStep === 'info'" :class="'step-' + activeStep" class="step">
            <h2>{{ $t('Vul de klantgegevens aan') }}</h2>

            <div v-if="quote.address" class="info-form">
              <formulate-input v-model="quote.address.first_name" :label="$t('Voornaam')" name="first_name" type="text" validation="required"></formulate-input>
              <formulate-input v-model="quote.address.last_name" :label="$t('Achternaam')" name="last_name" type="text" validation="required"></formulate-input>
              <formulate-input v-model="quote.address.email" :label="$t('E-mail')" name="email" type="email" validation="required|email"></formulate-input>
              <formulate-input v-model="quote.address.telephone" :label="$t('Telefoonnummer')" name="phone" type="text" validation="required"></formulate-input>
              <formulate-input v-model="quote.address.address1" :label="$t('Straat en huisnummer')" name="address1" type="text" validation="required"></formulate-input>
              <formulate-input v-model="quote.address.postal_code" :label="$t('Postcode')" name="postal_code" type="text"></formulate-input>
              <formulate-input v-model="quote.address.locality" :label="$t('Gemeente')" name="locality" type="text"></formulate-input>
              <formulate-input v-model="quote.address.country_code" :label="$t('Land')" :options="countries" name="country_code" type="select" @change="selectCountry"></formulate-input>
              <formulate-input v-model="quote.address.company_name" :label="$t('Bedrijfsnaam')" type="text"></formulate-input>
              <formulate-input v-model="quote.vat_number" :label="$t('Btw nummer')" type="text"></formulate-input>
              <formulate-input v-model="quote.vat_rate" :label="$t('Btw tarief')" :options="{ 999: $t('Btw verlegd'), 0: '0%', 6: '6%', 21: '21%' }" name="vat" type="select" validation="required"></formulate-input>
              <formulate-input v-model="quote.expires_on" :label="$t('Vervaldatum')" name="expire_on" type="date"></formulate-input>
            </div>

            <div class="actions">
              <button class="btn" @click="setStep('extras')">{{ $t('Volgende') }}</button>
            </div>
          </div>

          <div v-if="activeStep === 'installation_costs'" :class="'step-' + activeStep" class="step">
            <h2>{{ $t('Plaatsingskosten') }}</h2>

            <formulate-input v-if="quote.shutters && quote.shutters.length > 0" v-model="quote.installation_cost_shutters" :label="$t('Plaatsing rolluiken:') + ' ' + quote.shutters.length" class="with-prefix" type="number">
              <template #prefix>
                <span class="prefix">&euro;</span>
              </template>
            </formulate-input>
            <formulate-input v-if="quote.screens && quote.screens.length > 0" v-model="quote.installation_cost_screens" :label="$t('Plaatsing screens:') + ' ' + quote.screens.length" class="with-prefix" type="number">
              <template #prefix>
                <span class="prefix">&euro;</span>
              </template>
            </formulate-input>
            <formulate-input v-if="quote.articulating_screens && quote.articulating_screens.length > 0" v-model="quote.installation_cost_articulating_screens" :label="$t('Plaatsing zonneschermen:') + ' ' + quote.articulating_screens.length" class="width-prefix" type="number">
              <template #prefix>
                <span class="prefix">&euro;</span>
              </template>
            </formulate-input>
          </div>

          <div v-if="activeStep === 'overview'" :class="'step-' + activeStep" class="step">
            <h2>{{ $t('Overzicht') }}</h2>

            <div class="overview-details">
              <div v-for="articulatingScreen in quote.articulating_screens" :key="'as-' + articulatingScreen.id" class="calculator">
                <div class="flex">
                  <h3>{{ $t('Zonnescherm:') }} {{ articulatingScreen.name }}</h3>
                  <div class="buttons">
                    <router-link :to="{ name: 'ww.quotes.calc.as', params: { quoteId: quote.id, id: articulatingScreen.id } }" class="edit">
                      <Icon icon="bxs:edit" width="18"/>
                    </router-link>

                    <button class="duplicate" @click="duplicateArticulatingScreen(articulatingScreen)">
                      <Icon icon="bx:duplicate" width="18"/>
                    </button>

                    <button class="delete" @click="deleteArticulatingScreen(articulatingScreen)">
                      <Icon icon="akar-icons:trash-can" width="18"/>
                    </button>
                  </div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Breedte en uitval') }}</div>
                  <div class="value">{{ articulatingScreen.width }}mm x {{ articulatingScreen.reach }}mm</div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Motor') }}</div>
                  <div class="value">{{ $t(articulatingScreen.engine) }}</div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Bediening') }}</div>
                  <div class="value">{{ $t(articulatingScreen.connection) }}</div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Muursteunen') }}</div>
                  <div class="value">{{ $t('Inclusief') }} {{ articulatingScreen.wall_mounts_amount }} {{ $t('muursteunen') }}</div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Kleur kast') }}</div>
                  <div class="value">RAL{{ articulatingScreen.ral }}</div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Kleur doek') }}</div>
                  <div class="value">{{ articulatingScreen.fabric_color }}</div>
                </div>

                <div class="detail subtotal">
                  <div class="label">{{ $t('Prijs product') }}</div>
                  <div class="value">{{ $n(articulatingScreen.base_price, 'currency', 'nl-BE') }}</div>
                </div>

                <div class="detail options">
                  <div class="label">{{ $t('Opties') }}</div>
                  <template v-if="articulatingScreen.options && articulatingScreen.options.length > 0">
                    <div v-for="option in articulatingScreen.options" :key="'as-option-' + option.id" class="value">
                      <div class="title">{{ option.amount }}x {{ $t(option.name) }}</div>
                      <span>{{ $n(option.calculated_total, 'currency', 'nl-BE') }}</span>
                    </div>
                  </template>
                  <div v-else class="value">
                    {{ $t('Geen opties') }}
                  </div>
                </div>
                <div class="detail subtotal">
                  <div class="label">{{ $t('Prijs incl. opties') }}</div>
                  <div class="value">{{ $n(articulatingScreen.calculated_total_price, 'currency', 'nl-BE') }}</div>
                </div>
              </div>

              <div v-for="shutter in quote.shutters" :key="'shutter-' + shutter.id" class="calculator">
                <div class="flex">
                  <h3>{{ $t('Rolluik:') }} {{ shutter.name }}</h3>
                  <div class="buttons">
                    <router-link :to="{ name: 'ww.quotes.calc.shutter', params: { quoteId: quote.id, id: shutter.id } }" class="edit">
                      <Icon icon="bxs:edit" width="18"/>
                    </router-link>

                    <button class="duplicate" @click="duplicateShutter(shutter)">
                      <Icon icon="bx:duplicate" width="18"/>
                    </button>

                    <button class="delete" @click="deleteShutter(shutter)">
                      <Icon icon="akar-icons:trash-can" width="18"/>
                    </button>
                  </div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Type') }}</div>
                  <div class="value">{{ $t(shutter.type) }}</div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Breedte en hoogte') }}</div>
                  <div v-if="shutter.measurement_style === 'cabinet_rails'" class="value">{{ shutter.width }}mm (+ {{ shutter.added_width }}) x {{ shutter.height }}mm (+ {{ shutter.added_height }}mm)</div>
                  <div v-else-if="shutter.measurement_style === 'cabinet'" class="value">{{ shutter.width }}mm x {{ shutter.height }}mm (+ {{ shutter.added_height }}mm)</div>
                  <div v-else class="value">{{ shutter.width }}mm x {{ shutter.height }}mm</div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Motor') }}</div>
                  <div class="value">{{ $t(shutter.engine) }}</div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Bediening') }}</div>
                  <div class="value">{{ $t(shutter.connection) }}</div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Kleur kast') }}</div>
                  <div v-if="getShutterCabinetColor(shutter)" class="value">{{ getShutterCabinetColor(shutter).ral ? 'RAL ' + getShutterCabinetColor(shutter).ral : $t('Alternatieve RAL-kleur') }}</div>
                  <div v-else class="value">/</div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Kleur lamellen') }}</div>
                  <div v-if="getShutterSlatsColor(shutter)" class="value">{{ 'RAL ' + getShutterSlatsColor(shutter).ral + ' - ' + getShutterSlatsColor(shutter).name }}</div>
                  <div v-else class="value">/</div>
                </div>
                <div class="detail subtotal">
                  <div class="label">{{ $t('Prijs product') }}</div>
                  <div class="value">{{ $n(shutter.base_price, 'currency', 'nl-BE') }}</div>
                </div>
                <div v-if="shutter.shortened_engine" class="detail options">
                  <div class="label">{{ $t('Verkorte motor') }}</div>
                  <div class="value">
                    <div>{{ $t('Meerprijs') }}</div>
                    {{ $n(shutter.additional_price_shortened_engine, 'currency', 'nl-BE') }}
                  </div>
                </div>
                <div v-if="getShutterCabinetColor(shutter) && getShutterCabinetColor(shutter).cabinet_total" class="detail options">
                  <div class="label">{{ $t('Meerprijs kleur kast') }}</div>
                  <div class="value">
                    <div>{{ $t('Kast') }}</div>
                    {{ $n(getShutterCabinetColor(shutter).cabinet_total, 'currency', 'nl-BE') }}
                  </div>
                  <div class="value">
                    <div>{{ $t('Geleiders') }}</div>
                    {{ $n(getShutterCabinetColor(shutter).side_rails_total, 'currency', 'nl-BE') }}
                  </div>
                  <div class="value">
                    <div>{{ $t('Onderlat') }}</div>
                    {{ $n(getShutterCabinetColor(shutter).bottom_bar_total, 'currency', 'nl-BE') }}
                  </div>
                </div>
                <div v-if="getShutterSlatsColor(shutter) && getShutterSlatsColor(shutter).slats_total" class="detail options">
                  <div class="label">{{ $t('Meerprijs kleur lamellen') }}</div>
                  <div class="value">
                    <div>{{ getShutterSlatsColor(shutter).name }}</div>
                    {{ $n(getShutterSlatsColor(shutter).slats_total, 'currency', 'nl-BE') }}
                  </div>
                </div>
                <div class="detail options">
                  <div class="label">{{ $t('Opties') }}</div>
                  <template v-if="shutter.options && shutter.options.length > 0">
                    <div v-for="option in shutter.options" :key="'shutter-option-' + option.id" class="value">
                      <div class="title">{{ option.amount }}x {{ $t(option.name) }}</div>
                      <span>{{ $n(option.calculated_total, 'currency', 'nl-BE') }}</span>
                    </div>
                  </template>
                  <div v-else class="value">
                    {{ $t('Geen extra\'s') }}
                  </div>
                </div>
                <div class="detail subtotal">
                  <div class="label">{{ $t('Prijs incl. opties') }}</div>
                  <div class="value">{{ $n(shutter.calculated_total_price, 'currency', 'nl-BE') }}</div>
                </div>
              </div>

              <div v-for="screen in quote.screens" :key="'screen-' + screen.id" class="calculator">
                <div class="flex">
                  <h3>{{ $t('Screen:') }} {{ screen.name }}</h3>
                  <div class="buttons">
                    <router-link :to="{ name: 'ww.quotes.calc.screen', params: { quoteId: quote.id, id: screen.id } }" class="edit">
                      <Icon icon="bxs:edit" width="18"/>
                    </router-link>

                    <button class="duplicate" @click="duplicateScreen(screen)">
                      <Icon icon="bx:duplicate" width="18"/>
                    </button>

                    <button class="delete" @click="deleteScreen(screen)">
                      <Icon icon="akar-icons:trash-can" width="18"/>
                    </button>
                  </div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Type') }}</div>
                  <div class="value">{{ $t(screen.type) }}</div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Breedte en hoogte') }}</div>
                  <div v-if="screen.measurement_style === 'cabinet_rails'" class="value">{{ screen.width }}mm (+ {{ screen.added_width }}) x {{ screen.height }}mm (+ {{ screen.added_height }}mm)</div>
                  <div v-else-if="screen.measurement_style === 'cabinet'" class="value">{{ screen.width }}mm x {{ screen.height }}mm (+ {{ screen.added_height }}mm)</div>
                  <div v-else class="value">{{ screen.width }}mm x {{ screen.height }}mm</div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Motor') }}</div>
                  <div class="value">{{ $t(screen.engine) }}</div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Bediening') }}</div>
                  <div class="value">{{ $t(screen.connection) }}</div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Doeksoort') }}</div>
                  <div class="value">{{ $t(screen.fabric_type) }}</div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Kleur kast') }}</div>
                  <div v-if="getScreenCabinetColor(screen)" class="value">{{ getScreenCabinetColor(screen).ral ? 'RAL ' + getScreenCabinetColor(screen).ral : $t('Alternatieve RAL-kleur') }}</div>
                  <div v-else class="value">/</div>
                </div>
                <div class="detail">
                  <div class="label">{{ $t('Kleur doek') }}</div>
                  <div v-if="getScreenFabricColor(screen)" class="value">{{ getScreenFabricColor(screen).name }}</div>
                  <div v-else class="value">/</div>
                </div>
                <div class="detail subtotal">
                  <div class="label">{{ $t('Prijs product') }}</div>
                  <div class="value">{{ $n(screen.base_price, 'currency', 'nl-BE') }}</div>
                </div>
                <div v-if="screen.shortened_engine" class="detail options">
                  <div class="label">{{ $t('Verkorte motor') }}</div>
                  <div class="value">
                    <div>{{ $t('Meerprijs') }}</div>
                    {{ $n(screen.additional_price_shortened_engine, 'currency', 'nl-BE') }}
                  </div>
                </div>
                <div v-if="screen.fabric_type_addition_price" class="detail options">
                  <div class="label">{{ $t('Meerprijs doeksoort') }}</div>
                  <div class="value">
                    <div>{{ $t(screen.fabric_type) }}</div>
                    {{ $n(screen.fabric_type_addition_price, 'currency', 'nl-BE') }}
                  </div>
                </div>
                <div class="detail options">
                  <div class="label">{{ $t('Opties') }}</div>
                  <template v-if="screen.options && screen.options.length > 0">
                    <div v-for="option in screen.options" :key="'screen-option-' + option.id" class="value">
                      <div class="title">{{ option.amount }}x {{ $t(option.name) }}</div>
                      <span>{{ $n(option.calculated_total, 'currency', 'nl-BE') }}</span>
                    </div>
                  </template>
                  <div v-else class="value">
                    {{ $t('Geen extra\'s') }}
                  </div>
                </div>
                <div class="detail subtotal">
                  <div class="label">{{ $t('Prijs incl. opties') }}</div>
                  <div class="value">{{ $n(screen.calculated_total_price, 'currency', 'nl-BE') }}</div>
                </div>
              </div>

              <div class="calculator">
                <div class="flex">
                  <h3>{{ $t('Extra\'s') }}</h3>
                </div>

                <div class="detail options">
                  <template v-if="quote.extras && quote.extras.length > 0">
                    <div v-for="extra in quote.extras" :key="'extra-' + extra.id" class="value">
                      <div>
                        <div class="title">{{ extra.title }}</div>
                        <div class="description" v-html="extra.description"></div>
                      </div>
                      <span v-if="extra.price && extra.show_price">{{ $n(extra.price, 'currency', 'nl-BE') }}</span>
                      <span v-if="extra.percentage && extra.show_price">{{ extra.percentage }}%</span>
                    </div>
                  </template>
                  <div v-else class="value">
                    {{ $t('Geen extra\'s') }}
                  </div>
                </div>
              </div>

              <div v-if="quote.installation_cost_shutters || quote.installation_cost_articulating_screens || quote.installation_cost_screens" class="calculator">
                <div class="flex">
                  <h3>{{ $t('Plaatsingskosten') }}</h3>
                </div>

                <div class="detail options">
                  <div v-if="quote.shutters && quote.shutters.length && quote.installation_cost_shutters" class="value">
                    <div>{{ $t('Plaatsingskost rolluiken') }} ({{ quote.shutters.length }})</div>
                    {{ $n(quote.installation_cost_shutters, 'currency', 'nl-BE') }}
                  </div>

                  <div v-if="quote.screens && quote.screens.length && quote.installation_cost_screens" class="value">
                    <div>{{ $t('Plaatsingskost screens') }} ({{ quote.screens.length }})</div>
                    {{ $n(quote.installation_cost_screens, 'currency', 'nl-BE') }}
                  </div>

                  <div v-if="quote.articulating_screens && quote.articulating_screens.length && quote.installation_cost_articulating_screens" class="value">
                    <div>{{ $t('Plaatsingskost zonneschermen') }} ({{ quote.articulating_screens.length }})</div>
                    {{ $n(quote.installation_cost_articulating_screens, 'currency', 'nl-BE') }}
                  </div>
                </div>
              </div>
            </div>

            <div class="price-total">
              <div class="label">
                {{ $t('Totaalprijs') }}
              </div>
              <div>
                <div class="excl">{{ $t('Excl. btw') }} {{ $n(quote.calculated_total_without_vat, 'currency', 'nl-BE') }}</div>
                <div v-if="quote.vat_rate > 0 && quote.vat_rate < 999" class="excl">{{ $t('Btw') }} {{ quote.vat_rate }}% {{ $n(quote.calculated_total_vat, 'currency', 'nl-BE') }}</div>
                <div v-if="quote.vat_rate > 0 && quote.vat_rate < 999" class="incl">{{ $t('Incl. btw') }} {{ $n(quote.calculated_total_with_vat, 'currency', 'nl-BE') }}</div>
                <div class="price-info">{{ $t('Deze offerte kan gewijzigd worden na definitieve opmeting.') }}</div>
                <div class="overwrite-total">
                  <formulate-input v-model="quote.overwritten_total" class="with-prefix">
                    <template #prefix>
                      <span class="prefix">&euro;</span>
                    </template>
                  </formulate-input>
                </div>
              </div>
            </div>

            <div class="quote-actions">
              <div class="buttons">
                <button :class="{ 'generating': pdfGenerating }" :disabled="pdfGenerating" class="btn download" @click="generatePdf">
                  <div>
                    <img alt="download" src="@/assets/img/download_white.svg"/>
                    <span v-if="!pdfGenerating">{{ $t('Offerte downloaden') }}</span>
                    <span v-else>{{ $t('Offerte genereren') }}</span>
                  </div>
                  <img v-if="!pdfGenerating" alt="arrow" src="@/assets/img/arrow_blue.svg"/>
                  <Icon v-else icon="eos-icons:bubble-loading"/>
                </button>
                <button :class="{ 'active': showSignatures }" class="btn sign" @click="toggleSignatures">
                  <div>
                    <img alt="sign" src="@/assets/img/sign.svg"/> <span>{{ $t('Digitaal ondertekenen') }}</span>
                  </div>
                  <img alt="arrow" src="@/assets/img/arrow_green.svg"/>
                </button>
              </div>

              <div v-show="showSignatures" class="signatures">
                <div class="signature-wrapper">
                  <h3>{{ $t('Handtekening dealer') }}</h3>
                  <div v-if="!quote.signature_dealer">
                    <VueSignaturePad id="signature" ref="signature-dealer" :options="{onBegin: () => {$refs['signature-dealer'].resizeCanvas()}}" class="signature" height="200px"/>
                    <div class="signature-buttons">
                      <button class="btn-inverse" @click="saveSignature('dealer')">{{ $t('Handtekening opslaan') }}</button>
                      <button class="btn-inverse" @click="undoSignature('dealer')">{{ $t('Ongedaan maken') }}</button>
                    </div>
                  </div>
                  <div v-else class="signature-image">
                    <img :src="quote.signature_dealer.file.url" alt="Signature dealer">
                  </div>
                </div>

                <div class="signature-wrapper">
                  <h3>{{ $t('Handtekening klant') }}</h3>
                  <div v-if="!quote.signature_client">
                    <VueSignaturePad id="signature" ref="signature-client" :options="{onBegin: () => {$refs['signature-client'].resizeCanvas()}}" class="signature" height="200px"/>
                    <div class="signature-buttons">
                      <button class="btn-inverse" @click="saveSignature('client')">{{ $t('Handtekening opslaan') }}</button>
                      <button class="btn-inverse" @click="undoSignature('client')">{{ $t('Ongedaan maken') }}</button>
                    </div>
                  </div>
                  <div v-else class="signature-image">
                    <img :src="quote.signature_client.file.url" alt="Signature customer">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalculatorButton from '@/components/world/CalculatorButton'
import { ApiService } from '@/services/admin/api.service'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { DateTime } from 'luxon'
import Calculators from '@/calculators'
import { Icon } from '@iconify/vue2'
import { notification } from '@/notifications'

export default {
  name: 'Quote',
  components: {
    CalculatorButton,
    vSelect,
    Icon
  },
  mixins: [
    Calculators
  ],
  data () {
    return {
      activeStep: this.$route.query.tab || 'info',
      price: null,
      quote: null,
      showSignatures: false,
      pdfHash: null,
      pdfGenerating: false,
      pressgridId: null,
      pressgridInterval: null,
      datetime: DateTime,
      extras: [],
      quoteExtras: [],
      selectExtras: [],
      loading: true,
      statuses: [],
      articulatingScreenConnections: {}
    }
  },
  async mounted () {
    await this.setDealer()

    await this.fetchDealerExtras()
    await this.fetchQuoteStatuses()
    await this.fetchQuote()

    if (!this.quote.address) {
      this.quote.address = {}
    }

    this.loading = false
  },
  methods: {
    backToOverview () {
      this.setStep(this.activeStep)
      this.$router.push({ name: 'ww.quotes' })
    },
    async fetchDealerExtras () {
      this.extras = (await ApiService.fetchDealerQuoteExtras(this.dealer.id)).data.map(extra => {
        delete extra.id
        return extra
      })
    },
    async fetchQuote () {
      await ApiService.fetchQuote(this.$route.params.id).then(async quoteResponse => {
        if (quoteResponse.status === 200) {
          await this.normalizeQuote(quoteResponse.data)
        } else {
          await this.$router.push({ name: 'ww.quotes' })
          await notification(this.$t('Er ging iets mis met het ophalen van de offerte'), 'error')
        }
      })
    },
    async fetchQuoteStatuses () {
      await ApiService.fetchQuoteStatuses().then(res => {
        this.statuses = res.data
      })
    },
    async normalizeQuote (quote) {
      this.quote = quote

      if (this.quote.extras && this.quote.extras.length > 0) {
        this.quoteExtras = this.quote.extras.sort((a, b) => a.id > b.id ? 1 : -1)
        // this.quoteExtras.map(quoteExtra => {
        //   quoteExtra.price = quoteExtra.price
        //   quoteExtra.percentage = quoteExtra.percentage || 0
        //   return quoteExtra
        // })
      }
    },
    async setStep (step) {
      this.loading = true
      const quote = JSON.parse(JSON.stringify(this.quote))

      if (this.activeStep === 'extras' && this.quoteExtras) {
        const deletedQuoteExtras = []

        // find deleted quote extras
        for (const quoteExtra of quote.extras) {
          const deletedQuoteExtra = this.quoteExtras.find(extra => extra.id === quoteExtra.id)

          if (deletedQuoteExtra === undefined) {
            deletedQuoteExtras.push(quoteExtra)
          }
        }

        // delete deleted quote extras
        for (const quoteExtra of deletedQuoteExtras) {
          await ApiService.deleteQuoteExtra(quote.id, quoteExtra.id)
        }

        // create or patch quote extras
        for (const quoteExtra of this.quoteExtras) {
          quoteExtra.percentage = parseInt(quoteExtra.percentage)
          quoteExtra.percentage = quoteExtra.percentage || 0
          quoteExtra.price = parseInt(quoteExtra.price)
          quoteExtra.price = quoteExtra.price || 0

          if (!quoteExtra.id) {
            await ApiService.createQuoteExtra(this.quote.id, quoteExtra)
          } else {
            await ApiService.patchQuoteExtra(this.quote.id, quoteExtra.id, quoteExtra)
          }
        }
      }

      quote.vat_rate = parseInt(quote.vat_rate) === 999 ? null : quote.vat_rate

      delete quote.extras
      delete quote.signature_dealer
      delete quote.signature_client
      delete quote.articulating_screens
      delete quote.shutters
      delete quote.screens
      delete quote.created_by
      delete quote.last_updated_by
      delete quote.dealer
      delete quote.status

      if (quote.overwritten_total === '') {
        quote.overwritten_total = null
      }

      if (quote.installation_cost_articulating_screens === '') {
        quote.installation_cost_articulating_screens = null
      }

      if (quote.installation_cost_shutters === '') {
        quote.installation_cost_shutters = null
      }

      if (quote.installation_cost_screens === '') {
        quote.installation_cost_screens = null
      }

      const res = await ApiService.patchQuote(quote.id, quote)

      if (res.status === 200) {
        await this.normalizeQuote(res.data)
        this.activeStep = step
      } else {
        notification(this.$t(res.response.data.detail[0].msg), 'error')
      }

      this.loading = false
    },
    async setQuoteStatus (status) {
      this.quote.status_id = status.id
      await this.setStep(this.activeStep)
    },
    async selectExtra (extra) {
      const obj = {
        title: extra.title,
        show_price: extra.show_price,
        description: extra.description,
        price: extra.price,
        percentage: extra.percentage
      }

      this.quoteExtras.push(obj)

      // await ApiService.createQuoteExtra(this.quote.id, obj).then(async quoteExtraResponse => {
      //   if (quoteExtraResponse.status === 200) {
      //     await ApiService.fetchQuote(this.$route.params.id).then(async quoteResponse => {
      //       await this.normalizeQuote(quoteResponse.data)
      //     })
      //   }
      // })
    },
    async validateExtras (step = null) {
      this.$formulate.submit('extrasForm')
      const hasErrors = await this.$refs.extrasForm.hasValidationErrors()

      if (step) {
        if (!hasErrors) {
          await this.setStep(step)
        }
      } else {
        return !hasErrors
      }
    },
    selectCountry () {
      if (this.quote.vat_rate === null && this.quote.address.country_code === 'be') {
        this.quote.vat_rate = 21
      } else if (this.quote.address.country_code !== 'be') {
        this.quote.vat_rate = null
      }
    },
    getShutterCabinetColor (shutter) {
      return shutter.colors.find(color => color.component === 'cabinet_square' || color.component === 'cabinet_round')
    },
    getShutterSlatsColor (shutter) {
      return shutter.colors.find(color => color.component === 'slats')
    },
    getScreenCabinetColor (screen) {
      return screen.colors.find(color => color.component === 'cabinet')
    },
    getScreenFabricColor (screen) {
      return screen.colors.find(color => color.component === 'fabric')
    },
    /** signature methods **/
    async toggleSignatures () {
      await this.setStep('overview')
      this.showSignatures = !this.showSignatures
    },
    undoSignature (ref) {
      this.$refs['signature-' + ref].undoSignature()
    },
    async saveSignature (ref) {
      const { data } = this.$refs['signature-' + ref].saveSignature()

      const imgData = {
        image_base64: data
      }

      await ApiService.createQuoteSignature(imgData).then(async res => {
        if (res.status === 200) {
          if (ref === 'dealer') {
            this.quote.signature_dealer_id = res.data.id
          } else {
            this.quote.signature_client_id = res.data.id
          }

          await this.setStep(this.activeStep)
        }
      })
    },
    /** pdf methods **/
    async generatePdf () {
      await this.setStep('overview')

      if (this.quote.id) {
        this.pdfGenerating = true

        const pdfConfiguration = {
          printBackground: false,
          format: 'A4',
          footerTemplate: '<div style="width: 100%; font-family: Barlow, sans-serif; padding-right: 40px; text-align: right; color: #0F1D61; font-weight: 500; font-size: 9px;" class="pageNumber"></div>',
          displayHeaderFooter: true,
          margin: {
            top: '0px',
            right: '0px',
            bottom: '60px',
            left: '0px'
          }
        }

        await ApiService.createQuotePdfPy(this.quote.id, pdfConfiguration).then(res => {
          if (res.status === 200) {
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = res.data.url
            a.download = 'offerte-' + this.quote.number + '.pdf'
            document.body.appendChild(a)
            a.click()

            this.pdfGenerating = false
          }
        })
      }
    },
    async addArticulatingScreen () {
      await ApiService.createArticulatingScreen({
        quote_id: this.quote.id
      }).then(async res => {
        if (res.status === 200) {
          await this.$router.push({ name: 'ww.quotes.calc.as', params: { quoteId: this.quote.id, id: res.data.id } })
        }
      })
    },
    async deleteArticulatingScreen (articulatingScreen) {
      await ApiService.deleteArticulatingScreen(articulatingScreen.id).then(async res => {
        if (res.status === 200) {
          await this.fetchQuote()
          notification(this.$t('Zonnescherm verwijderd!'))
        }
      })
    },
    async duplicateArticulatingScreen (articulatingScreen) {
      await ApiService.duplicateArticulatingScreen(articulatingScreen.id).then(async res => {
        if (res.status === 200) {
          await this.fetchQuote()
          notification(this.$t('Zonnescherm gedupliceerd!'))
        }
      })
    },
    async deleteShutter (shutter) {
      await ApiService.deleteShutter(shutter.id).then(async res => {
        if (res.status === 200) {
          await this.fetchQuote()
          notification(this.$t('Rolluik verwijderd!'))
        }
      })
    },
    async duplicateShutter (shutter) {
      await ApiService.duplicateShutter(shutter.id).then(async res => {
        if (res.status === 200) {
          await this.fetchQuote()
          notification(this.$t('Rolluik gedupliceerd!'))
        }
      })
    },
    async deleteScreen (shutter) {
      await ApiService.deleteScreen(shutter.id).then(async res => {
        if (res.status === 200) {
          await this.fetchQuote()
          notification(this.$t('Screen verwijderd!'))
        }
      })
    },
    async duplicateScreen (shutter) {
      await ApiService.duplicateScreen(shutter.id).then(async res => {
        if (res.status === 200) {
          await this.fetchQuote()
          notification(this.$t('Screen gedupliceerd!'))
        }
      })
    }
  }
}
</script>
